:root {
  --p-br-over: 5px;
  --p-bg-over-light: rgba(255,255,255,0.5);
  --p-bg-over-dark: rgba(0,0,0,0.5);
  --p-fg-over-light: rgba(255,255,255,0.5);
  --p-fg-over-dark: rgba(0,0,0,0.5);
  --p-fg-light: #222;
  --p-radius: 0.25rem;
  --p-radius-m: 0.5rem;
  --p-radius-l: 1rem;
  /* assume small as default */
  --p-md: 30em;
  --p-lg: 60em;
  --p-c-none: transparent;
  --p-c-work: rgb(25,169,116);
  --p-c-personal: rgb(53,126,221);
  --p-c-unmapped: rgb(255,65,54);
  --p-c-conflict: rgb(255,65,180);

}
/* these are from tachyons */
@custom-media --breakpoint-not-small screen and (min-width: 30em);
@custom-media --breakpoint-medium screen and (min-width: 30em) and (max-width: 60em);
@custom-media --breakpoint-large screen and (min-width: 60em);

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overscroll-behavior: none none;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url(/background.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hover-outline {
  border: solid 1px transparent;
}
.hover-outline:hover {
  border: solid 1px gray;
}
.hover-lighten:hover {
  background-color: rgba(255,255,255,0.7) !important;
}
.hover-lighten5:hover {
  background-color: rgba(255,255,255,0.5) !important;
}
.hover-lighten2:hover {
  background-color: rgba(255,255,255,0.2) !important;
}
.hover-unhide {
  color: transparent;
}
.hover-lighten:hover .hover-unhide {
  color: gray;
}
.hover-fade:hover {
  background-color: rgba(100,100,100,0.5) !important;
}
.hover-darken:hover {
  background-color: rgba(0,0,0,0.2) !important;
}

h1, h2, h3, h4, p {
  margin: 0;
}

#root {
  width: 100%;
  height: inherit;
  max-height: inherit;
}

.clearfix {
  zoom: 1;
}
.clearfix:after {
    clear: both;
    content: '';
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.card-red,.card-green,.card-blue {
  color: white;
}
.card-unknown {
  color: dark;
}
.card-red {
  background-color: rgba(200,0,0,.6) !important;
}
.card-green {
  background-color: rgba(0,150,0,.6) !important;
}
.card-blue {
  background-color: rgba(0,0,200,.5) !important;
}
.card-unknown {
  background-color: inherit;
}
.navdark, .navbar {
  background-color: var(--p-fg-over-dark);
}
.navbar {
  width: 100%;
}
a:visited, a {
  text-decoration: none;
  color: #325CA7;
}
.navlink {
  cursor: pointer;
  text-decoration: none;
  margin-left: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  color: white;
}
.navlink:hover {
  background-color: var(--p-bg-over-light);
}
.br-over {
  border-radius: var(--p-br-over) var(--p-br-over) var(--p-br-over) var(--p-br-over);
}

/* common buttons */
button {
  border: 0;
}
.button {
  background: #55f;
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}
.button:hover {
  background: #77f;
}
div.square{
  position: relative;
  /* width: 100px;  specify width in HTML */
}
div.square:before{
  content: "";
  display: block;
  padding-top: 100%;  /* ratio of 1:1*/
}
div.square div.square-guts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/*
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
*/
.editing {
  background-color: white;
  color: black;
  padding: 0.25rem;
}

.hide-small {
  display: none !important;
  visibility: hidden;
}

.hide-medium {
  display: none !important;
  visibility: hidden;
}

@media screen and (min-width: 30em) {
  .hide-small {
    display: inherit !important;
    visibility: inherit;
  }
}

@media screen and (min-width: 60em) {
  .hide-small {
    display: inherit !important;
    visibility: inherit;
  }
  .hide-medium {
    display: inherit !important;
    visibility: inherit;
  }
}

/* PITA - this should work at the module level; putting here for now */
.rtb {
  margin: 0;
  padding: 0;
  color: white;
  border: solid 1px transparent;
}
.bg-work {
  background-color: var(--p-c-work);
}
.rtbwork {
  border-color: var(--p-c-work);
}

.bg-personal {
  background-color: var(--p-c-personal);
}
.rtbpersonal {
  border-color: var(--p-c-personal);
}

.bg-unmapped {
  background-color: var(--p-c-unmapped);
}
.rtbunmapped {
  border-color: var(--p-c-unmapped);
}

.bg-conflict {
  background-color: var(--p-c-conflict);
}
.rtbconflict {
  border-color: var(--p-c-conflict);
}

.rtbnone:hover, .rtbwork:hover, .rtbpersonal:hover, .rtbunmapped:hover, .rtbconflict:hover {
  border-color: white;
}

/* loading spinner */
.preloading{
  z-index:2147483647;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,.7);
}
.loading, .loading-body {
  display:block;
  position:relative;
  left:50%;
  top:50%;
  width:10vw;
  height:10vw;
  margin: -10vw 0 0 -10vw;
}
.loading-body {
  text-align: center;
  top: 45%;
}
.loading {
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color:#4866ad;
  -webkit-animation:spin 5s linear infinite;
  animation:spin 5s linear infinite;
}
.loading:before{
  content:"";
  position:absolute;
  top:5px;
  left:5px;
  right:5px;
  bottom:5px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color:#8998c8;
  -webkit-animation:spin 3s linear infinite;
  animation:spin 3s linear infinite;
}
.loading:after{
  content:"";
  position:absolute;
  top:15px;
  left:15px;
  right:15px;
  bottom:15px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color:#b3bde0;
  -webkit-animation:spin 1.5s linear infinite;
  animation:spin 1.5s linear infinite;
}
@-webkit-keyframes spin{
  0%{
    -webkit-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    transform:rotate(0deg)
  }
100%{
    -webkit-transform:rotate(360deg);
    -ms-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}
@keyframes spin{
  0%{
    -webkit-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    transform:rotate(0deg)
  }
100%{
    -webkit-transform:rotate(360deg);
    -ms-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}
.loading-leave {
  opacity: 1;
}
.loading-leave.loading-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* */
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* ************************************************************************** */
/* react-tabs.  TODO: Get scss working on the build */
/*
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
*/
.react-tabs__tab-list {
  /* border-bottom: 1px solid #aaa; */
  /*
  margin: 0 0 10px;
  padding: 0;
  */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*width: 100%;*/
  // align-content: stretch;
}
.react-tabs__tab {
  /*display: inline-block;
  /*
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  */
  list-style: none;
  flex: 1;
  white-space: nowrap;
  color: #eee;
  background-color: rgba(255,255,255,0.3);
  position: relative;
  text-align: center;
  padding: .75rem .75rem; /* 6px 12px; /* convert to tachyons rem distances */
  cursor: pointer;
  font-size: 1.25rem;
}
.react-tabs__tab--selected {
  background-color: rgba(255,255,255,0.8);
  color: #111;
  span.fas  {
    color: #666;
  }
  /* border-color: #aaa; */
  /* border-radius: 5px 5px 0 0; */
}
/* not sure where this is used -BJG
.react-tabs__tab--disabled {
  background-color: rgba(255,255,255,0.3);
  color: gray;
  cursor: default;
}
*/
.react-tabs__tab:hover {
  background-color: rgba(255,255,255,0.45);
  /*span.fas  {
    color: #666;
  }
  */
}
.react-tabs__tab--selected:hover {
  background-color: rgba(255,255,255,0.9);
  /*
  span.fas  {
    color: #666;
  }
  */
}
/*.react-tabs__tab:focus {
  /* box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}
  */
.react-tabs__tab:focus:after {
  /*
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
  */
}

.react-tabs__tab-panel {
  display: none;
  background-color: rgba(255,255,255,0.8);
}

.react-tabs__tab-panel--selected {
  display: block;
}
/* ************************************************************************** */
/* end of react-tabs */

.auth-buttons {
  flex-direction: row;
  white-space: nowrap;
  border-color: red;
}
.auth-signin {
  flex-direction: column;
  white-space: nowrap;
  // border-color: green;
  /*
  display: inline-block;
  background: white;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  color: #444;
  /*
  width: 190px;
  */
}
.auth-signin:hover {
  cursor: pointer;
}
.auth-signin .icon {
  /*
  display: inline-block; */
  /* width: 42px;
  vertical-align: middle;
  height: 42px;
  */
}
.auth-signin .label {
  /* display: inline-block;
  vertical-align: middle; */
  /*
  padding-left: 42px;
  padding-right: 42px;
  */
  padding-left: .5rem;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

/* hr with text in the middle -- hrstrike */
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}

// Future investigation.  http://css3.bradshawenterprises.com/cfimg/
// consider using img tags at a low layer
.background {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  // animation: bgAnimate 20s linear infinite;

  // background: url(http://www.gstatic.com/webp/gallery/1.jpg) 0% 0% cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: inherit;
  background-position: center center;
  background-size: cover;
  margin: 0;
  /*
  -webkit-animation: animateBg 20s linear infinite;
  -webkit-animation-name: animateBg;
  -webkit-animation-duration: 100s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  */
}
.bgWrapper {
  /*
  width: 100vw;
  height: 100vh;
  */
  overflow: hidden;
}
.bgContent {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
}

@keyframes bgAnimate {
    from { transform: translateX(100%); }
    to { transform: translateX(120%); }
/*    from { transform: scale(1); }
    to { transform: scale(1.1); }
    */
}
/* END of background image */
ul.arrow {
  list-style: none;
  li:before {
//    content: '\1F95E';
//    content: '\12665';
 //   content: '\u{25bb}';
    content: '▻ ';
 //   content: '&#x25BB';
//  margin-left: -20px;
//  margin-right: 10px;
  }
}
